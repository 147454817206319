import React, { Component } from "react";
import Layout from "../Layout";
import { BreadCrumb } from "../Shared/BreadCrumb";
import { SolutionsList } from "./list";

interface SolutionsProps {
  list: any;
  footerContent: any;
}

export default class Solutions extends Component<SolutionsProps> {
  render(): JSX.Element {
    const { list, footerContent } = this.props;

    return (
      <Layout contentfulFooterContentForPages={footerContent}>
        <BreadCrumb
          activePath={{ routeName: "Solutions" }}
          routingPaths={[{ path: "/", routeName: "Home" }]}
          title="Solutions"
        />
        <section className="section section-md bg-gray-100 pb-0 text-center">
          <div className="container">
            <div className="row row-xl-40 align-items-center">
              <SolutionsList solutions={list} />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
