import SEO from "@/components/SEO";
import Solutions from "@/components/Solutions";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const SolutionsPage: FC<PageProps> = () => (
  <StaticQuery
    query={graphql`
      query SolutionPage {
        contentfulSolutionsPage {
          #     SEO
          seo {
            documentTitle
            metaTitle
            metaUrl
            metaAuthor
            description {
              description
            }
            metaKeywords
          }

          solutions {
            id
            title
            shortDescription
            slug
            solutionThumbnail {
              file {
                url
              }
            }
          }

          #     Footer
          footerContent {
            content {
              raw
            }
          }
        }
      }
    `}
    render={({
      contentfulSolutionsPage: {
        seo: {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        },
        solutions,
        footerContent,
      },
    }) => {
      return (
        <Fragment>
          <SEO
            contentfulSeo={{
              documentTitle,
              metaTitle,
              metaDescription: description,
              metaKeywords,
              metaUrl,
              metaAuthor: metaAuthor,
            }}
          />
          <Solutions list={solutions} footerContent={footerContent} />
        </Fragment>
      );
    }}
  />
);

export default SolutionsPage;
